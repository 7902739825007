class TimeFunction
{
    static Linear = 0;
    static EaseIn = 1;
    static EaseOut = 2;
    static EaseInOut = 3;

    #timeFunctions = {
        [this.constructor.Linear] : function(x) {
            return x;
        },
        [this.constructor.EaseIn]: function(x) {
            return Math.pow(x, 1.675);
        },
        [this.constructor.EaseOut]: function(x) {
            return 1 - Math.pow(1 - x, 1.675);
        },
        [this.constructor.EaseInOut]: function(x) {
            return .5 * (Math.sin((x - .5) * Math.PI) + 1);
        }
    };

    #timeFunction = 0;
    constructor(timeFunction) {
        switch (timeFunction)
        {
            case this.constructor.EaseIn:
                this.#timeFunction = this.constructor.EaseIn;
                break;
            case this.constructor.EaseOut:
                this.#timeFunction = this.constructor.EaseOut;
                break;
            case this.constructor.EaseInOut:
                this.#timeFunction = this.constructor.EaseInOut;
                break;
            case this.constructor.Linear:
            default:
                this.#timeFunction = this.constructor.Linear;
                break;
        }
    }

    /**
     * @param x
     * @returns {float}
     */
    sample(x)
    {
        return this.#timeFunctions[this.#timeFunction](x);
    }
}
window.TimeFunction = TimeFunction;
